<template>
  <v-container class="mt-5">
    <v-row>
      <v-col class="col col-sm-8 offset-sm-2 col-xs-12 offset-xs-0">
        <div
         :class="[$vuetify.breakpoint.smAndDown
          ? 'headline font-weight-bold main-title text-center '
          : 'font-weight-bold main-title text-center']"
        >{{ $t('UcarMobileServiceAppIsNowAvailableOnAppStoreAndGooglePlay') }}</div>
      </v-col>
    </v-row>

    <v-row class="mt-2">
        <v-col cols="6" sm="3" offset-sm="3">
          <a rel="canonical" href="https://apps.apple.com/ie/app/ucar-mobile-car-care-repair/id1495701232#?platform=iphone" target="_blank" aria-label="UcarMobile android store link">
            <v-img
            src="../assets/icons/app_store.png"
            height="50"
            contain class="mb-3"></v-img>
          </a>
        </v-col>
        <v-col cols="6" sm="3">
          <a rel="canonical" href="https://play.google.com/store/apps/details?id=com.uCarMobile.obdconnect" target="_blank" aria-label="UcarMobile apple store link">
            <v-img
            src="../assets/icons/play_store.png"
            height="50"
            contain class="mb-3"></v-img>
          </a>
        </v-col>
    </v-row>

    <v-row class="mt-2">
        <v-col cols="12" sm="4" offset-sm="2">
            <v-img
            src="../assets/images/techinician_detailng_vehicle.webp"
            height="350"
            class="mb-3"></v-img>
        </v-col>
        <v-col cols="12" sm="4">
            <v-img
            src="../assets/images/mechanic_fixing_vehicle.png"
            height="350"
            class="mb-3"></v-img>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'DownloadSection',
};
</script>
