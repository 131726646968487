var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c("v-row", { staticClass: "offset-md-2 col-md-8 col-12" }, [
            _c("div", { staticClass: "col-md-8" }, [
              _c(
                "h1",
                {
                  staticClass: "text-center col-12",
                  staticStyle: {
                    "text-decoration": "underline",
                    "font-size": "2em !important",
                    margin: "0.67em 0 !important",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("InstallationInstructions")) + " ")]
              ),
            ]),
            _c("div", { staticClass: "col-md-4" }, [
              _c("img", {
                staticStyle: {
                  display: "block",
                  "margin-left": "auto",
                  "margin-right": "auto",
                },
                attrs: {
                  width: "180",
                  height: "180",
                  src: require("../assets/images/qr-code.png"),
                  alt: "imagen",
                },
              }),
            ]),
          ]),
          _c("v-row", { staticClass: "offset-md-2 col-md-8 offset-1 col-10" }, [
            _c(
              "h2",
              {
                staticClass: "main-title col-12",
                staticStyle: {
                  "font-size": "1.7em !important",
                  margin: "0 !important",
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("StepOne")) + " ")]
            ),
            _c(
              "p",
              {
                staticClass: "col-12 text-left description-text",
                staticStyle: { "margin-bottom": "0 !important" },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "InstallTheAppByPointingYourSmartphoneCameraToTheQRcodeAboveOrByNavigating"
                      )
                    ) +
                    " "
                ),
              ]
            ),
          ]),
          _c("v-row", { staticClass: "offset-md-2 col-md-8 offset-1 col-10" }, [
            _c(
              "h2",
              {
                staticClass: "main-title col-12",
                staticStyle: {
                  "font-size": "1.7em !important",
                  margin: "0 !important",
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("StepTwo")) + " ")]
            ),
            _c(
              "p",
              {
                staticClass: "col-12 text-left description-text",
                staticStyle: { "margin-bottom": "0 !important" },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("FromTheHomeScreenTapOnTheSideMenuAtTheTop")
                    ) +
                    " "
                ),
              ]
            ),
          ]),
          _c("v-row", { staticClass: "offset-md-2 col-md-8 offset-1 col-10" }, [
            _c(
              "a",
              {
                staticClass: "main-title text-end col-12",
                staticStyle: {
                  "font-size": "1.5em !important",
                  margin: "0 0 40px 0 !important",
                  "font-weight": "bold",
                  "text-decoration": "none",
                  color: "#198655 !important",
                },
                attrs: { href: "mailto:admin@ucarmobile.com" },
              },
              [_vm._v(" admin@ucarmobile.com ")]
            ),
          ]),
        ],
        1
      ),
      _c("download-section"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }