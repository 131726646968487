var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "mt-5" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "col col-sm-8 offset-sm-2 col-xs-12 offset-xs-0" },
            [
              _c(
                "div",
                {
                  class: [
                    _vm.$vuetify.breakpoint.smAndDown
                      ? "headline font-weight-bold main-title text-center "
                      : "font-weight-bold main-title text-center",
                  ],
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "UcarMobileServiceAppIsNowAvailableOnAppStoreAndGooglePlay"
                      )
                    )
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-2" },
        [
          _c("v-col", { attrs: { cols: "6", sm: "3", "offset-sm": "3" } }, [
            _c(
              "a",
              {
                attrs: {
                  rel: "canonical",
                  href: "https://apps.apple.com/ie/app/ucar-mobile-car-care-repair/id1495701232#?platform=iphone",
                  target: "_blank",
                  "aria-label": "UcarMobile android store link",
                },
              },
              [
                _c("v-img", {
                  staticClass: "mb-3",
                  attrs: {
                    src: require("../assets/icons/app_store.png"),
                    height: "50",
                    contain: "",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("v-col", { attrs: { cols: "6", sm: "3" } }, [
            _c(
              "a",
              {
                attrs: {
                  rel: "canonical",
                  href: "https://play.google.com/store/apps/details?id=com.uCarMobile.obdconnect",
                  target: "_blank",
                  "aria-label": "UcarMobile apple store link",
                },
              },
              [
                _c("v-img", {
                  staticClass: "mb-3",
                  attrs: {
                    src: require("../assets/icons/play_store.png"),
                    height: "50",
                    contain: "",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-2" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4", "offset-sm": "2" } },
            [
              _c("v-img", {
                staticClass: "mb-3",
                attrs: {
                  src: require("../assets/images/techinician_detailng_vehicle.webp"),
                  height: "350",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4" } },
            [
              _c("v-img", {
                staticClass: "mb-3",
                attrs: {
                  src: require("../assets/images/mechanic_fixing_vehicle.png"),
                  height: "350",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }