<template>
  <v-container>
    <v-row>
      <v-row class="offset-md-2 col-md-8 col-12">
        <div class="col-md-8">
          <h1 class="text-center col-12"
              style="text-decoration: underline;
                 font-size: 2em !important;
                 margin: 0.67em 0 !important;">
            {{ $t('InstallationInstructions') }}
          </h1>
        </div>
        <div class="col-md-4">
          <img width="180" height="180" style="display: block;
              margin-left: auto;
              margin-right: auto;"
              src="../assets/images/qr-code.png" alt="imagen">
        </div>
      </v-row>
      <v-row class="offset-md-2 col-md-8 offset-1 col-10">
        <h2 class="main-title col-12" style="font-size: 1.7em !important; margin: 0 !important;">
            {{ $t('StepOne') }}
        </h2>
        <p class="col-12 text-left description-text" style="margin-bottom: 0 !important;">
            {{ $t('InstallTheAppByPointingYourSmartphoneCameraToTheQRcodeAboveOrByNavigating') }}
        </p>
      </v-row>
      <v-row class="offset-md-2 col-md-8 offset-1 col-10">
        <h2 class="main-title col-12" style="font-size: 1.7em !important; margin: 0 !important;">
          {{ $t('StepTwo') }}
        </h2>
        <p class="col-12 text-left description-text" style="margin-bottom: 0 !important;">
          {{ $t('FromTheHomeScreenTapOnTheSideMenuAtTheTop') }}
        </p>
      </v-row>
      <v-row class="offset-md-2 col-md-8 offset-1 col-10">
        <a href="mailto:admin@ucarmobile.com" class="main-title text-end col-12"
            style="font-size: 1.5em !important;
                   margin: 0 0 40px 0 !important;
                   font-weight: bold;
                   text-decoration: none;
                   color: #198655 !important;">
          admin@ucarmobile.com
        </a>
      </v-row>
    </v-row>
    <download-section></download-section>
  </v-container>
</template>
<script>
import DownloadSection from '@/components/DownloadSection.vue';

export default {
  name: 'InstallInstructions',
  components: {
    DownloadSection,
  },
};
</script>
<style scoped lang="css">
  /*@import '../assets/styles/css/maintenance-plan.css';*/

  .description-text {
    color: #003951;
    font-size: 1.2em;
  }

  a:active {
    color: #198655 !important;
  }
</style>
